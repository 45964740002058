const menus = [
    {
        id: 1,
        name: 'Home',
        link: '/'
        // namesub: [
        //     {
        //         id: 1,
        //         sub: 'Home 01',
        //         links: '/'
        //     },
        //     {
        //         id: 2,
        //         sub: 'Home 02',
        //         links: '/home-02'
        //     },
        // ]
    },
    
    {
        id: 2,
        name: 'Explore',
        link: '/explore-02'
        // id: 2,
        // name: 'Explore',
        // namesub: [
        //     {
        //         id: 1,
        //         sub: 'Explore 01',
        //         links: '/explore-01'
        //     },
        //     {
        //         id: 2,
        //         sub: 'Explore 02',
        //         links: '/explore-02'
        //     },
        //     {
        //         id: 3,
        //         sub: 'Creator',
        //         links: '/creator'
        //     },
        //     {
        //         id: 4,
        //         sub: 'Item',
        //         links: '/item'
        //     },
        //     {
        //         id: 5,
        //         sub: 'Item Details',
        //         links: '/item-details'
        //     },
            
        // ],
    },
    // {
    //     id: 3,
    //     name: 'Community',
    //     namesub: [
    //         {
    //             id: 1,
    //             sub: 'Blog',
    //             links: '/blog'
    //         },
    //         {
    //             id: 2,
    //             sub: 'Blog Details',
    //             links: '/blog-details'
    //         },
    //     ],
    // },
    // {
    //     id: 4,
    //     name: 'Page',
    //     namesub: [
    //         {
    //             id: 1,
    //             sub: 'Authors',
    //             links: '/authors'
    //         },
    //         {
    //             id: 2,
    //             sub: 'Wallet Connect',
    //             links: '/connect-wallet'
    //         },
    //         {
    //             id: 3,
    //             sub: 'Create Item',
    //             links: '/create-item'
    //         },
    //         {
    //             id: 4,
    //             sub: 'Login',
    //             links: '/login'
    //         },
    //         {
    //             id: 5,
    //             sub: 'Register',
    //             links: '/register'
    //         },
    //     ],
    // },
    // {
    //     id: 4,
    //     name: 'Contact',
    //     namesub: [
    //         {
    //             id: 1,
    //             sub: 'Contact 01',
    //             links: '/contact'
    //         },
    //     ],
    // },
    
]

export default menus;