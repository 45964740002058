import React from 'react';
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import Countdown from "react-countdown";
// import useUpcomingData from '../../../assets/data/upcomingData';
import img1 from '../../../assets/images/product-item/item-1.jpg';
import imga1 from '../../../assets/images/avatar/avt-7.jpg'
import API from '../../../conf';

const Upcoming = props => {
    // console.log(props)
    // const {data, loading, error} = props;
    // console.log(upcData)
    // console.log(upcError)
    // console.log(props)
    // const [data, error, loading] = props;
    // console.log(data);
    // const data = props.data;
    // console.log(upcData)
  return (
    <section className="tf-live-auctions tf-section bg-color-1">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <div className="sc-heading style-2 has-icon">
                    <div className="content-left">

                        <div className="inner">
                            <div className="group">
                                <div className="icon"><i className="ripple"></i></div>
                                <h3>Upcoming</h3>
                                
                            </div>
                            <p className="desc">Upcoming most popular gaming digital nft market place </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-12">
            <div className="swiper-container">
                <div className="swiper-wrapper live-auc">
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={30}
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                                },
                            767: {
                                slidesPerView: 2,
                            },
                            991: {
                                slidesPerView: 3,
                            },
                            1300: {
                                slidesPerView: 4,
                                },
                            }}
                        navigation
                        pagination={{ clickable: true }}
                        scrollbar={{ draggable: true }}
                        >
                        {props.data &&
                            props.data.map((item,index) => (
                                <SwiperSlide key={index}>
                                    <UpcomingItem item={item} />
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </div>
            </div>
                
            </div>
        </div>
    </div>
</section>
  );
};


const UpcomingItem = props => {
    // console.log(Date.now() + 500000000);
    let pad = props.item.attributes;
    // const item = props.item.attributes;
    // console.log(pad);

    let item = {
        img: props.item.attributes.banner.data.attributes.url,
        // img: img1,
        title: pad.title,
        tags: pad.tokens.data,
        imgAuthor: imga1,
        name:'Daniel M.Bivens',
        price: '5.23 ETH',
        priceChange: '$32.420',
        // startDate: props.item
    }
    // console.log(pad)

    return (<div className="swiper-slide">
        <div className="slider-item">
            <div className="sc-product-item">
                <div className="product-img">
                    <img src={API.strapi + item.img} alt="Bidzen" />
                    <div className="countdown">
                    <Countdown date={new Date(pad.start_date).toISOString()}>
                        <span>You are good to go!</span>
                    </Countdown>
                    </div>
                    <Link to={"/details/" + props.item.id}
                        className="sc-button style letter"><span>View more</span></Link>
                    {item.tags.map(tag => <label>{tag.attributes.code}</label>)}
                </div>
                <div className="product-content">
                    <h5 className="title"><Link to={"/details/" + props.item.id}>{item.title}</Link> </h5>
                    {/* <div className="product-author flex">
                        <div className="avatar">
                            <img src={item.imgAuthor} alt="Bidzen" />
                        </div>
                        <div className="infor">
                            <div className="author-name"><Link to="/authors">{item.name}</Link></div>
                            <span>Creator</span>
                        </div>
                    </div> */}
                    {/* <div className="product-price">
                        <div className="title">Current Bid</div>
                        <div className="price">
                            <span>{item.price}</span>
                            <span>= {item.priceChange}</span>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    </div>)
}

export default Upcoming;
