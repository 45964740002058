import { useState, useEffect } from "react";
import API from "../../conf";


export default function useActiveLaunchpads() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
   
    useEffect(() => {
       let now = new Date();
       fetch(API.api + `/launchpads?populate=*&pagination[pageSize]=100&filters[start_date][$lte]=` + now.toISOString() + `&filters[dueto_date][$gt]=` + now.toISOString())
         .then((response) => {
           // console.log(response)
           if (!response.ok) {
             setError(response.status)
             return response.status
             // throw new Error(
             //   `This is an HTTP error: The status is ${response.status}`
             // );
           }
           return response.json();
         })
         .then((actualData) => {
           setData(actualData);
           setLoading(false);
           return
         })
         .catch((err) => {
           setError(err.message);
           setLoading(false);
           return
         });
    }, []);
   
    return [data, loading, error];
}