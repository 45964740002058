import React , {useState, useEffect} from 'react';
import { Link , useParams} from 'react-router-dom'
import Header from '../components/header/Header';
import Countdown from 'react-countdown';
import { Tab, Tabs, TabList, TabPanel  } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import LiveAution from '../components/layouts/home02/LiveAution';
import dataLiveAution from '../assets/fake-data/dataLiveAution'
import  Newsletters from '../components/layouts/Newsletters';
import Footer from '../components/footer/Footer';

import img1 from '../assets/images/avatar/avt-6.jpg'
import img2 from '../assets/images/avatar/avt-2.jpg'
import img3 from '../assets/images/avatar/avt-4.jpg'
import imgdetail1 from '../assets/images/product-item/auction-detail.jpg'
import avt1 from '../assets/images/avatar/avt-4.jpg'
import avt2 from '../assets/images/avatar/avt-6.jpg'
import avt3 from '../assets/images/avatar/avt-3.jpg'
import useLaunchpadDetails from '../assets/data/useLaunchpadDetails';
import useActiveLaunchpads from '../assets/data/useActiveLaunchpads';
import API from '../conf';
import Live from '../components/layouts/launchpad/Live';
import FooterStyle2 from '../components/footer/FooterStyle2';
const LaunchpadDetails = () => {
    const { id } = useParams();
    const [launchpadId, setLaunchpadId] = useState(id.toString())
    const [data, loading, error] = useLaunchpadDetails(launchpadId);
    const [liveData, liveLoading, liveError] = useActiveLaunchpads();
    // console.log(data, loading, error)
    let details = data?.data?.attributes;
    // console.log(details && new Date(details.start_date).getTime())
    useEffect(() => {
        if (!id) return;
        setLaunchpadId(id.toString())
        // console.log(id)
    }, [id])
    // useEffect(() => {
    //     if (!launchpadId) return;
        

    // }, [launchpadId])
    const [dataHistory] = useState(
        [
            {
                img: img1,
                name:"@Johnson",
                time: "8 hours ago ",
                price: "25 ETH ",
            },
            {
                img: img2,
                name:"@Johnson",
                time: "8 hours ago ",
                price: "25 ETH ",
            },
            {
                img: img3,
                name:"@Johnson",
                time: "8 hours ago ",
                price: "25 ETH ",
            },
        ]
    )

    return <div className='item-details'>
        <Header />
        {/* <section className="fl-page-title">
            <div className="overlay"></div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="page-title-inner flex">
                            <div className="page-title-heading">
                                <h2 className="heading">Auctions Details</h2>
                            </div>
                            <div className="breadcrumbs">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Auctions Details</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        {details && 
        <section className="tf-section item-details-page">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-12 col-md-12">
                            <div className="item-media">
                                <div className="media">
                                    {/* <img src={imgdetail1} alt="Bidzen" /> */}
                                    <img src={API.strapi + details.banner.data.attributes.url} alt="QSILaunchpad" />
                                </div>
                                {new Date(details.start_date) > Date.now() ?
                                    <div className="countdown style-2">
                                        <Countdown date={new Date(details.start_date).getTime()} />
                                    </div>
                                : '' }

                                {new Date(details.dueto_date) > Date.now() ? 
                                    <div className="countdown style-2">
                                        <Countdown date={new Date(details.dueto_date).getTime()} />
                                    </div>
                                : ''}
                                
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 col-md-12">
                            <div className="content-item">
                                <h3>{details && details.title}</h3>
                                <p style={{whiteSpace:"pre-wrap"}} className="mg-bt-42">{details && details.description}</p>
                                {/* <div className="author-item">
                                    <div className="avatar">
                                        <img src={avt1} alt="Bidzen" />
                                    </div>
                                    <div className="infor">
                                        <div className="create">Owner By</div>
                                        <h6><Link to="/authors">Duane M. Mendoza</Link> </h6>
                                        <div className="widget-social">
                                            <ul>
                                                <li><Link to="#" className="active"><i className="fab fa-facebook-f"></i></Link>
                                                </li>
                                                <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
                                                <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                                                <li><Link to="#"><i className="fab fa-linkedin-in"></i></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <ul className="list-details-item">
                                    <li><span className="name">Current Price 1.5 ETH</span><span
                                            className="price">$500.89</span> <span className="pagi">1 of 5</span> </li>
                                    <li>Size 14000 x 14000 px</li>
                                    <li> Volume Traded 64.1</li>
                                </ul> */}
                                {/* <div className="author-bid">
                                    <div className="author-item">
                                        <div className="avatar">
                                            <img src={avt2} alt="Bidzen" />
                                        </div>
                                        <div className="infor">
                                            <h6><Link to="/authors">Keith J. Kelley</Link> </h6>
                                            <div className="create">Creators</div>
                                        </div>
                                    </div>
                                    <div className="author-item">
                                        <div className="avatar">
                                            <img src={avt3} alt="Bidzen" />
                                        </div>
                                        <div className="infor">
                                            <h6><Link to="/authors">David Michels</Link> </h6>
                                            <div className="create">Collection</div>
                                        </div>
                                    </div>
                                </div> */}
                                
                                
                                    <div className="infor-bid">
                                        <div className="content-left">
                                            <h6>Progress</h6>
                                            <div className="price">0/{details.goal_xlm} XLM</div>
                                        </div>
                                    </div>
                                
                                {/* <Link to="/connect-wallet"
                                    className="sc-button style letter style-2 style-item-details"><span>Place Bid</span>
                                </Link> */}
                                <div className="flat-tabs themesflat-tabs">
                                <Tabs>
                                        <TabList>
                                        {/* <Tab>Bid</Tab> */}
                                        <Tab>Tokens</Tab>
                                        <Tab>Details</Tab>
                                        </TabList>

                                        {/* <TabPanel>
                                            <ul className="bid-history-list">
                                            {
                                                dataHistory.map((item, index) => (
                                                    <li key={index}>
                                                        <div className="content">
                                                            <div className="author-item">
                                                                <div className="avatar">
                                                                    <img src={item.img} alt="Bidzen" />
                                                                </div>
                                                                <div className="infor">
                                                                    <p>Bid listed for <span className="status">{item.price}</span> 
                                                                    {item.time}
                                                                        by <span className="creator">{item.name}</span> </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))
                                            }
                                            </ul>
                                        </TabPanel> */}
                                        
                                        <TabPanel>
                                            <ul className="bid-history-list">
                                                <li>
                                                    <div className="content" style={{width:"100%"}}>
                                                        <div className="author-item" style={{display: "flex", flexWrap:"wrap"}}>
                                                            {details.tokens.data.map((token,index) => (
                                                                <div key={index} 
                                                                    style={{display:"flex", margin:"0", padding:"0",}}
                                                                    className="col-xl-6 col-lg-6 col-md-4 col-sm-12 col-xs-12">
                                                                    <div className="sc-author style-2" style={{width:"100%", padding: "5px", margin: "5px"}}>
                                                                        <div className="card-avatar">
                                                                            {/* <img src={item.img} alt="Token" /> */}
                                                                        </div>
                                                                        <div className="infor">
                                                                            <h5>
                                                                                {/* <Link to="/authors"> */}
                                                                                    {token.attributes.code}
                                                                                {/* </Link> */}
                                                                            </h5>
                                                                            <div className="details">
                                                                                
                                                                            {token.attributes.issuer.slice(0, -51)}...{token.attributes.issuer.slice(51)}
                                                                            </div>
                                                                            <ul className="rating">
                                                                                <li style={{margin:"auto"}}><i className="fas fa-star"></i></li>
                                                                                <li style={{margin:"auto"}}><i className="fas fa-star"></i></li>
                                                                                <li style={{margin:"auto"}}><i className="fas fa-star"></i></li>
                                                                                <li style={{margin:"auto"}}><i className="fas fa-star"></i></li>
                                                                                <li style={{margin:"auto"}}><i className="fas fa-star"></i></li>
                                                                                
                                                                            </ul>
                                                                        </div>
                                                                        {/* <div className="button-follow"> */}
                                                                            {/* <Link to="/login" className="sc-button style-2"><span>Follow</span></Link> */}
                                                                        {/* </div> */}
                                                                    </div>
                                                                </div>
                                                            )) }

                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </TabPanel>
                                        <TabPanel>
                                            <div className="provenance">
                                                <p style={{whiteSpace:"pre-wrap"}}>{details.details}</p>
                                            </div>
                                        </TabPanel>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
        }
        {liveData && <Live data={liveData.data} loading={liveLoading} error={liveError}/>}
        {/* <LiveAution data={dataLiveAution} /> */}
        {/* <Newsletters /> */}
        <FooterStyle2 />
        {/* <Footer /> */}
    </div>;
};

export default LaunchpadDetails;
