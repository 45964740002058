
import img1 from '../images/slider/img-slider-1.png'

const dataSlider = [
    {
        title: "Discover and collect your favorite digital NTFs",
        description: "Quis autem vel eum iure reprehenderit qui in ea voluptates esse quam nihil molestiae consequatur veillum",
        img: img1,
    },
    {
        title: "Discover and collect your favorite digital NTFs",
        description: "Quis autem vel eum iure reprehenderit qui in ea voluptates esse quam nihil molestiae consequatur veillum",
        img: img1,
    },
    
]

export default dataSlider;