import { useState, useEffect } from "react";
// import {API_URL } from "./config.js";
import API from "../../conf";

export default function useLaunchpadDetails(id) {
 const [data, setData] = useState(null);
 const [loading, setLoading] = useState(true);
 const [error, setError] = useState(null);

 useEffect(() => {
  if (!id) return;
  fetch(API.api + `/launchpads/` + id + `?populate=*`) // pagination[pageSize]=100
    .then((response) => {
      // console.log(response)
      if (!response.ok) {
        setError(response.status)
        return response.status
        // throw new Error(
        //   `This is an HTTP error: The status is ${response.status}`
        // );
      }
      return response.json();
    })
    .then((actualData) => {
      setData(actualData);
      setLoading(false);
      return
      // () => setData(actualData) //, setLoading(false)
    })
    .catch((err) => {
      setError(err.message);
      setLoading(false);
      return
    });
  }, [id]);

  return [data, loading, error];
//  return <div className="App">App</div>;
}
