import img1 from '../images/product-item/item-32.jpg'
import img2 from '../images/product-item/item-33.jpg'
import img3 from '../images/product-item/item-34.jpg'
import img4 from '../images/product-item/item-35.jpg'
import img5 from '../images/product-item/item-36.jpg'
import img6 from '../images/product-item/item-37.jpg'
import img7 from '../images/product-item/item-38.jpg'
import img8 from '../images/product-item/item-39.jpg'
import img9 from '../images/product-item/item-collect-9.jpg'
import img10 from '../images/product-item/item-collect-10.jpg'
import img11 from '../images/product-item/item-collect-11.jpg'
import img12 from '../images/product-item/item-collect-12.jpg'
import avt from '../images/avatar/avt-7.jpg'
import avtlist1 from '../images/avatar/avt-8.jpg'
import avtlist2 from '../images/avatar/avt-9.jpg'
import avtlist3 from '../images/avatar/avt-10.jpg'
import avtlist4 from '../images/avatar/avt-11.jpg'

const dataPopularCollection = [
    {
        img: img1,
        title: '‘’3D Space Rocket With Smoke Premium’’',
        tags: 'RUN',
        avt: avt ,
        name: 'Daniel M. Bivens',
        price: '5.23 ETH',
        priceChange: '$32.420',
        avtList: [
            {
                img1:avtlist1
            },
            {
                img1:avtlist2
            },
            {
                img1:avtlist3
            },
            {
                img1:avtlist4
            },
        ]
    },
    {
        img: img2,
        title: '‘’3D Space Rocket With Smoke Premium’’',
        tags: 'RUN',
        avt: avt ,
        name: 'Daniel M. Bivens',
        price: '5.23 ETH',
        priceChange: '$32.420',
        avtList: [
            {
                img1:avtlist1
            },
            {
                img1:avtlist2
            },
            {
                img1:avtlist3
            },
            {
                img1:avtlist4
            },
        ]
    },
    {
        img: img3,
        title: '‘’3D Space Rocket With Smoke Premium’’',
        tags: 'RUN',
        avt: avt ,
        name: 'Daniel M. Bivens',
        price: '5.23 ETH',
        priceChange: '$32.420',
        avtList: [
            {
                img1:avtlist1
            },
            {
                img1:avtlist2
            },
            {
                img1:avtlist3
            },
            {
                img1:avtlist4
            },
        ]
    },
    {
        img: img4,
        title: '‘’3D Space Rocket With Smoke Premium’’',
        tags: 'RUN',
        avt: avt ,
        name: 'Daniel M. Bivens',
        price: '5.23 ETH',
        priceChange: '$32.420',
        avtList: [
            {
                img1:avtlist1
            },
            {
                img1:avtlist2
            },
            {
                img1:avtlist3
            },
            {
                img1:avtlist4
            },
        ]
    },
    {
        img: img5,
        title: '‘’3D Space Rocket With Smoke Premium’’',
        tags: 'RUN',
        avt: avt ,
        name: 'Daniel M. Bivens',
        price: '5.23 ETH',
        priceChange: '$32.420',
        avtList: [
            {
                img1:avtlist1
            },
            {
                img1:avtlist2
            },
            {
                img1:avtlist3
            },
            {
                img1:avtlist4
            },
        ]
    },
    {
        img: img6,
        title: '‘’3D Space Rocket With Smoke Premium’’',
        tags: 'RUN',
        avt: avt ,
        name: 'Daniel M. Bivens',
        price: '5.23 ETH',
        priceChange: '$32.420',
        avtList: [
            {
                img1:avtlist1
            },
            {
                img1:avtlist2
            },
            {
                img1:avtlist3
            },
            {
                img1:avtlist4
            },
        ]
    },
    {
        img: img7,
        title: '‘’3D Space Rocket With Smoke Premium’’',
        tags: 'RUN',
        avt: avt ,
        name: 'Daniel M. Bivens',
        price: '5.23 ETH',
        priceChange: '$32.420',
        avtList: [
            {
                img1:avtlist1
            },
            {
                img1:avtlist2
            },
            {
                img1:avtlist3
            },
            {
                img1:avtlist4
            },
        ]
    },
    {
        img: img8,
        title: '‘’3D Space Rocket With Smoke Premium’’',
        tags: 'RUN',
        avt: avt ,
        name: 'Daniel M. Bivens',
        price: '5.23 ETH',
        priceChange: '$32.420',
        avtList: [
            {
                img1:avtlist1
            },
            {
                img1:avtlist2
            },
            {
                img1:avtlist3
            },
            {
                img1:avtlist4
            },
        ]
    },
    {
        img: img9,
        title: '‘’3D Space Rocket With Smoke Premium’’',
        tags: 'RUN',
        avt: avt ,
        name: 'Daniel M. Bivens',
        price: '5.23 ETH',
        priceChange: '$32.420',
        avtList: [
            {
                img1:avtlist1
            },
            {
                img1:avtlist2
            },
            {
                img1:avtlist3
            },
            {
                img1:avtlist4
            },
        ]
    },
    {
        img: img10,
        title: '‘’3D Space Rocket With Smoke Premium’’',
        tags: 'RUN',
        avt: avt ,
        name: 'Daniel M. Bivens',
        price: '5.23 ETH',
        priceChange: '$32.420',
        avtList: [
            {
                img1:avtlist1
            },
            {
                img1:avtlist2
            },
            {
                img1:avtlist3
            },
            {
                img1:avtlist4
            },
        ]
    },
    {
        img: img11,
        title: '‘’3D Space Rocket With Smoke Premium’’',
        tags: 'RUN',
        avt: avt ,
        name: 'Daniel M. Bivens',
        price: '5.23 ETH',
        priceChange: '$32.420',
        avtList: [
            {
                img1:avtlist1
            },
            {
                img1:avtlist2
            },
            {
                img1:avtlist3
            },
            {
                img1:avtlist4
            },
        ]
    },
    {
        img: img12,
        title: '‘’3D Space Rocket With Smoke Premium’’',
        tags: 'RUN',
        avt: avt ,
        name: 'Daniel M. Bivens',
        price: '5.23 ETH',
        priceChange: '$32.420',
        avtList: [
            {
                img1:avtlist1
            },
            {
                img1:avtlist2
            },
            {
                img1:avtlist3
            },
            {
                img1:avtlist4
            },
        ]
    },
    {
        img: img1,
        title: '‘’3D Space Rocket With Smoke Premium’’',
        tags: 'RUN',
        avt: avt ,
        name: 'Daniel M. Bivens',
        price: '5.23 ETH',
        priceChange: '$32.420',
        avtList: [
            {
                img1:avtlist1
            },
            {
                img1:avtlist2
            },
            {
                img1:avtlist3
            },
            {
                img1:avtlist4
            },
        ]
    },
    {
        img: img2,
        title: '‘’3D Space Rocket With Smoke Premium’’',
        tags: 'RUN',
        avt: avt ,
        name: 'Daniel M. Bivens',
        price: '5.23 ETH',
        priceChange: '$32.420',
        avtList: [
            {
                img1:avtlist1
            },
            {
                img1:avtlist2
            },
            {
                img1:avtlist3
            },
            {
                img1:avtlist4
            },
        ]
    },
    {
        img: img3,
        title: '‘’3D Space Rocket With Smoke Premium’’',
        tags: 'RUN',
        avt: avt ,
        name: 'Daniel M. Bivens',
        price: '5.23 ETH',
        priceChange: '$32.420',
        avtList: [
            {
                img1:avtlist1
            },
            {
                img1:avtlist2
            },
            {
                img1:avtlist3
            },
            {
                img1:avtlist4
            },
        ]
    },
    {
        img: img4,
        title: '‘’3D Space Rocket With Smoke Premium’’',
        tags: 'RUN',
        avt: avt ,
        name: 'Daniel M. Bivens',
        price: '5.23 ETH',
        priceChange: '$32.420',
        avtList: [
            {
                img1:avtlist1
            },
            {
                img1:avtlist2
            },
            {
                img1:avtlist3
            },
            {
                img1:avtlist4
            },
        ]
    },
    {
        img: img5,
        title: '‘’3D Space Rocket With Smoke Premium’’',
        tags: 'RUN',
        avt: avt ,
        name: 'Daniel M. Bivens',
        price: '5.23 ETH',
        priceChange: '$32.420',
        avtList: [
            {
                img1:avtlist1
            },
            {
                img1:avtlist2
            },
            {
                img1:avtlist3
            },
            {
                img1:avtlist4
            },
        ]
    },
    {
        img: img6,
        title: '‘’3D Space Rocket With Smoke Premium’’',
        tags: 'RUN',
        avt: avt ,
        name: 'Daniel M. Bivens',
        price: '5.23 ETH',
        priceChange: '$32.420',
        avtList: [
            {
                img1:avtlist1
            },
            {
                img1:avtlist2
            },
            {
                img1:avtlist3
            },
            {
                img1:avtlist4
            },
        ]
    },
    {
        img: img7,
        title: '‘’3D Space Rocket With Smoke Premium’’',
        tags: 'RUN',
        avt: avt ,
        name: 'Daniel M. Bivens',
        price: '5.23 ETH',
        priceChange: '$32.420',
        avtList: [
            {
                img1:avtlist1
            },
            {
                img1:avtlist2
            },
            {
                img1:avtlist3
            },
            {
                img1:avtlist4
            },
        ]
    },
    {
        img: img8,
        title: '‘’3D Space Rocket With Smoke Premium’’',
        tags: 'RUN',
        avt: avt ,
        name: 'Daniel M. Bivens',
        price: '5.23 ETH',
        priceChange: '$32.420',
        avtList: [
            {
                img1:avtlist1
            },
            {
                img1:avtlist2
            },
            {
                img1:avtlist3
            },
            {
                img1:avtlist4
            },
        ]
    },
]
export default dataPopularCollection;