import React, { useState } from 'react';
import Header from '../components/header/Header';
import Slider01 from '../components/slider/Slider01';
import Slider02 from '../components/slider/Slider02';

import dataSlider from '../assets/fake-data/dataSlider';
import dataSlider2 from '../assets/fake-data/dataSlider2';

import LiveAution from '../components/layouts/home/LiveAution';

import dataLiveAution from '../assets/fake-data/dataLiveAution'
import LatestCollections from '../components/layouts/home/LatestCollections';
import dataCollections from '../assets/fake-data/dataCollections'
import BestSeller from '../components/layouts/home/BestSeller';
import dataBestSeller from '../assets/fake-data/dataBestSeller'
import TrendingCollections from '../components/layouts/home/TrendingCollections';
import dataTrendingCollections from '../assets/fake-data/dataTrendingCollections'
import Category from '../components/layouts/home/Category';
import dataCategory from '../assets/fake-data/dataCategory'
import Footer from '../components/footer/Footer';
import { Newsletters } from '../components/layouts/home/Newsletters';
import Upcoming from '../components/layouts/launchpad/Upcoming';
import useUpcomingData from '../assets/data/upcomingData';
import useUpcomingLaunchpads from '../assets/data/useUpcomingLaunchpads';
import useFinishedLaunchpads from '../assets/data/useFinishedLaunchpads';
import useActiveLaunchpads from '../assets/data/useActiveLaunchpads';
import Finished from '../components/layouts/launchpad/Finished';
import Live from '../components/layouts/launchpad/Live';
import FooterStyle2 from '../components/footer/FooterStyle2';

// 

const Home = () => {
    const [upcData, upcLoading, upcError] = useUpcomingLaunchpads();
    const [endData, endLoading, endError] = useFinishedLaunchpads();
    const [liveData, liveLoading, liveError] = useActiveLaunchpads();
    // console.log(liveData.data.length)
    // console.log(data)
    return <div className='home-1'>
        <Header />
        {/* <Slider01 data={dataSlider} /> */}

        <Slider02 data={dataSlider2} />

        {upcData && upcData.data && upcData.data.length >= 3 && <Upcoming data={upcData.data} loading={upcLoading} error={upcError}/>}
        {liveData && liveData.data && liveData.data.length >= 3 &&<Live data={liveData.data} loading={liveLoading} error={liveError}/>}
        {endData && endData.data && endData.data.length >= 3 && <Finished data={endData.data} loading={endLoading} error={endError}/>}

        {/* <LatestCollections data={dataCollections} />
        <BestSeller data={dataBestSeller} />
        <TrendingCollections data={dataTrendingCollections} />
        <Category data={dataCategory} />
        <Newsletters /> */}
        {/* <Footer /> */}
        <FooterStyle2 />
    </div>;
};

export default Home;
