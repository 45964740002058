// upcoming launchpads
// http://localhost:1337/api/launchpads?filters[start_date][$gt]=2025-11-27T22:00:00.000Z TODAY date in this format

// ended launchpads
// http://localhost:1337/api/launchpads?filters[dueto_date][$lte]=2023-11-27T22:00:00.000Z TODAY , less or equal from today to get the finished launchpads

// current launchpads
// localhost:1337/api/launchpads?filters[start_date][$lte]=2023-05-30T22:00:00.000Z&filters[dueto_date][$gt]=2023-05-30T22:00:00.000Z today timestamp in both cases

import { useState, useEffect } from "react";
import API from "../../conf";

export default function useUpcomingLaunchpads() {
 const [data, setData] = useState(null);
 const [loading, setLoading] = useState(true);
 const [error, setError] = useState(null);

 useEffect(() => {
    let now = new Date();
    fetch(API.api + `/launchpads?populate=*&pagination[pageSize]=100&filters[start_date][$gt]=` + now.toISOString())
      .then((response) => {
        // console.log(now.toISOString())
        if (!response.ok) {
          setError(response.status)
          return response.status
          // throw new Error(
          //   `This is an HTTP error: The status is ${response.status}`
          // );
        }
        return response.json();
      })
      .then((actualData) => {
        setData(actualData);
        setLoading(false);
        return
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
        return
      });
  }, []);

  return [data, loading, error];
}


// export default useUpcomingLaunchpads();